import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";

import {Link} from "@bolteu/kalep-react";

interface Props {
    listingId: number;
}

const ViewVehicleDetails = ({listingId}: Props) => {
    const {i18n} = useI18n();

    const {getListingPath} = useAbsolutePath();

    return (
        <Link href={getListingPath(listingId)} target="_blank" overrideClassName="font-semibold">
            {i18n("application_wizard.listing_data.view_vehicle_details")}
        </Link>
    );
};

export default ViewVehicleDetails;
