import {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {useI18n} from "common/hooks/useI18n";
import {ApplicationContextProvider} from "common/providers/appStateProvider";
import config from "config/index";

import {VehicleMarketplaceNoAuthService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {SelectOption, Typography} from "@bolteu/kalep-react";

import ListingsTable from "./components/ListingsTable";

const ListingsPage = () => {
    const {i18n} = useI18n();
    const appState = useContext(ApplicationContextProvider);
    const [selectedCountry, setSelectedCountry] = useState<VehicleMarketplaceNoAuthService.Country | null>(null);
    const [selectedCity, setSelectedCity] = useState<SelectOption | null>(null);
    const params = useParams();

    useEffect(() => {
        if (appState.cities !== null) {
            const currentCity = appState.cities.find((c) => c.name === params.city);
            if (currentCity) {
                setSelectedCountry(appState.countries?.find((c) => c.code === currentCity.country) ?? null);
                setSelectedCity({value: currentCity.id, title: currentCity.local_name ?? currentCity.name});
                if (!appState.isAppReady) {
                    appState.setIsAppReady(true);
                }
            } else {
                window.location.href = config.driverPortalUrl;
            }
        }
    }, [params.city, appState]);

    return (
        <>
            <div className="flex w-full flex-col justify-end gap-7 px-16 pt-10">
                <Typography variant="heading-m-accent" fontSize="text-6xl">
                    {i18n("banner.title")} <br />
                    {i18n("banner.subtitle")}
                </Typography>
                <Typography paddingBottom={3}>{i18n("banner.description")}</Typography>
                <div className="-ml-16 w-screen bg-[#0C2C1C] py-16 px-6 md:px-16">
                    <span className="text-3xl font-semibold text-white">
                        {i18n("banner.weekly_driving", {
                            earnUpTo: (
                                <span className="text-action-secondary">
                                    {i18n("banner.earn_up_to", {
                                        amount: selectedCountry?.weekly_earnings_estimate ?? "",
                                    })}
                                </span>
                            ),
                        })}
                    </span>
                </div>
            </div>
            <ListingsTable selectedCity={selectedCity} setSelectedCity={setSelectedCity} />
        </>
    );
};

export default ListingsPage;
