import {IntlShape} from "react-intl";

import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

export function getDateFormat(intl: IntlShape): string {
    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];

    // There's a bug in date-fns which returns dd.mm.y for DE locale instead of dd.MM.yyyy
    // PR is submitted, can remove this when it will be merged: https://github.com/date-fns/date-fns/pull/3278
    if (intl.locale === "de-de") {
        return "dd.MM.yyyy";
    }

    const formattedDate = dateFnsLocale?.formatLong?.date({width: "short"}) as string | undefined;
    return formattedDate ?? "DD/MM/YYYY";
}
