import {useCallback} from "react";
import {Carousel} from "react-responsive-carousel";

import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {IconButton} from "@bolteu/kalep-react";
import {ArrowLeft, ArrowRight} from "@bolteu/kalep-react-icons";

import "react-responsive-carousel/lib/styles/carousel.min.css";

enum ArrowType {
    LEFT = "left",
    RIGHT = "right",
}

interface Props {
    images: VehicleMarketplaceService.ListingImageUrl[];
}

export const ImageCarousel = ({images}: Props) => {
    const isMobile = useTailwindViewport() === "sm";

    const renderCarouselArrow = useCallback(
        (type: ArrowType) => (clickHandler: () => void, _hasPrev: boolean, label: string) => {
            if (isMobile || images.length === 1) {
                return null;
            }
            const icon = type === ArrowType.LEFT ? <ArrowLeft /> : <ArrowRight />;
            const position = type === ArrowType.LEFT ? "left-0 ml-2" : "right-0 mr-2";
            return (
                <div className={`absolute top-[calc(50%-18px)] z-10 ${position}`}>
                    <IconButton size="sm" variant="floating" onClick={clickHandler} icon={icon} aria-label={label} />
                </div>
            );
        },
        [images.length, isMobile],
    );

    const emptyCarouselStatusFormatter = useCallback(() => "", []);

    const renderCarouselIndicator = useCallback(
        (
            clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
            isSelected: boolean,
            index: number,
            label: string,
        ) => {
            if (images.length === 1) {
                return null;
            }
            const selectedClasses = "w-6";
            const classes = "w-2 opacity-60";
            return (
                <span
                    key={index}
                    onClick={clickHandler}
                    onKeyDown={clickHandler}
                    tabIndex={0}
                    role="button"
                    aria-label={`${label} ${index + 1}`}
                    className={`mx-1 inline-block h-2 cursor-pointer rounded-full bg-white shadow transition-[opacity,width] ease-in ${
                        isSelected ? selectedClasses : classes
                    }`}
                />
            );
        },
        [images.length],
    );

    return (
        <div className="overflow-hidden rounded-lg">
            <Carousel
                dynamicHeight={false}
                emulateTouch
                ariaLabel="Vehicle images carousel"
                infiniteLoop
                renderArrowPrev={renderCarouselArrow(ArrowType.LEFT)}
                renderArrowNext={renderCarouselArrow(ArrowType.RIGHT)}
                showThumbs={false}
                statusFormatter={emptyCarouselStatusFormatter}
                useKeyboardArrows
                renderIndicator={renderCarouselIndicator}
            >
                {images.map((image) => (
                    <div key={image.image_id}>
                        <img
                            src={image.thumb_800_url ?? image.thumb_400_url}
                            className="max-h-[250px] w-full object-cover md:max-h-[416px]"
                            alt="Vehicle"
                        />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};
