import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Button, Typography} from "@bolteu/kalep-react";

interface Props {
    listingData: VehicleMarketplaceService.GetListingData;
    isSticky?: boolean;
    requestVehicleClicked: () => void;
    isRequestVehicleButtonShown: boolean;
}

export const PriceWithRequestVehicle = ({
    listingData,
    isSticky,
    requestVehicleClicked,
    isRequestVehicleButtonShown,
}: Props) => {
    const {i18n} = useI18n();
    const isMobile = useTailwindViewport() === "sm";

    const stickyClasses = isSticky ? "sticky bottom-0 left-0 bg-white px-6 py-4 shadow" : "";
    const shouldShowRequestButton = (isRequestVehicleButtonShown && !isMobile) || (isMobile && isSticky);

    return (
        <div className={`flex items-center justify-between gap-4 ${stickyClasses}`}>
            <div>
                <Typography
                    fontSize={isSticky ? "text-2xl" : "text-4xl"}
                    fontWeight="semibold"
                >{`${listingData.offer_price}${listingData.currency_symbol}`}</Typography>
                {listingData.offer_period && (
                    <Typography fontSize="text-sm" color="secondary">
                        {i18n(`listing.payment_type.${listingData.offer_period}`)}
                    </Typography>
                )}
            </div>
            {shouldShowRequestButton && (
                <Button onClick={requestVehicleClicked}>{i18n("listing.request_vehicle")}</Button>
            )}
        </div>
    );
};
