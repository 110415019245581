import {useCallback, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Page} from "common/components/page/Page";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/api/apiProvider";
import {FetchStatus} from "@fleet/common/hooks/useFetch";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Spinner} from "@bolteu/kalep-react";

import {ApplicationWizardDesktopView} from "./ApplicationWizardDesktopView";
import {ApplicationWizardMobileView} from "./ApplicationWizardMobileView";

const fetchFunction = (api: Api, query: VehicleMarketplaceService.ApplicationWizardDataRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleApplicationGetApplicationWizardData(query);

export const ApplicationPage = () => {
    const {getListingPath} = useAbsolutePath();
    const navigate = useNavigate();

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const params = useParams();
    const listingId = Number(params.listing_id);
    const applicationId = Number(params.application_id);

    const {data, fetch, status} = useFetch(fetchFunction);

    const onClose = useCallback(() => {
        navigate(getListingPath(listingId));
    }, [navigate, getListingPath, listingId]);

    const onSubmit = useCallback(() => {
        // TODO: Should go to the next page (temporary redirects back to listing details)
        navigate(getListingPath(listingId));
    }, [navigate, getListingPath, listingId]);

    useEffect(() => {
        if (fetch) {
            fetch({application_id: applicationId});
        }
    }, [fetch, applicationId]);

    if (status === FetchStatus.Loading || status === FetchStatus.Init) {
        return (
            <Page>
                <div className="mt-20 w-full text-center">
                    <Spinner />
                </div>
            </Page>
        );
    }

    if (Number.isNaN(listingId) || Number.isNaN(applicationId) || status === FetchStatus.Error || !data) {
        return (
            <Page>
                <ErrorView type={ErrorViewType.NotFound} className="!bg-neutral-25" />
            </Page>
        );
    }

    if (isMobileView) {
        return (
            <ApplicationWizardMobileView
                applicationId={applicationId}
                listingId={listingId}
                listingData={data.listing_data}
                listingTranslations={data.listing_translations}
                wizardFields={data.wizard_fields}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        );
    }

    return (
        <ApplicationWizardDesktopView
            applicationId={applicationId}
            listingData={data.listing_data}
            listingTranslations={data.listing_translations}
            wizardFields={data.wizard_fields}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
};
