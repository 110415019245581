import {ValidatorResult} from "@fleet/common/hooks/useFetch";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {InfoGroupText} from "../InfoGroupText";
import {Radio} from "../Radio";
import {FormValueType, UseFormValueType} from "../types";

const getApiToFormValues = (formFields: FleetOwnerRegistrationService.FormField[]): FormValueType => {
    return formFields.reduce((acc, field) => {
        const {name, value, type} = field;

        switch (type) {
            case FleetOwnerRegistrationService.FormFieldType.RADIOGROUP:
                acc[name] = value;
                break;
            default:
                break;
        }
        return acc;
    }, {} as FormValueType);
};

function getFormToApiValues<T>(formData: FormValueType, formFields: FleetOwnerRegistrationService.FormField[]): T {
    const request = Object.entries(formData).reduce<Record<string, string | boolean | string[]>>(
        (acc, [key, value]) => {
            const field = formFields.filter((f) => !f.hidden).find((f) => f.name === key);
            if (!field) {
                return acc;
            }

            const formFieldType = field.type;
            switch (formFieldType) {
                case FleetOwnerRegistrationService.FormFieldType.INFOTEXTGROUP:
                    break;
                case FleetOwnerRegistrationService.FormFieldType.RADIOGROUP:
                    acc[key] = value as string;
                    break;
                default:
                    break;
            }
            return acc;
        },
        {},
    );
    return request as T;
}

const getErrorMessage = (
    fieldConfig: FleetOwnerRegistrationService.BaseFormField,
    validatorErrors: ValidatorResult[],
): string | undefined => {
    const {name} = fieldConfig;
    const validationError = validatorErrors.filter((error) => error.property === name)?.[0];
    return validationError?.error;
};

const getChildrenErrorMessages = (
    fieldConfig: FleetOwnerRegistrationService.BaseFormField,
    validatorErrors: ValidatorResult[],
): ValidatorResult[] => {
    const {name} = fieldConfig;
    const childrenErrors = validatorErrors.filter((error) => error.property === name)?.[0]?.children;
    return childrenErrors || [];
};

const getFormField = (
    field: FleetOwnerRegistrationService.FormField,
    validatorResults: ValidatorResult[],
    useFormValue: UseFormValueType,
) => {
    const {type} = field;
    switch (type) {
        case FleetOwnerRegistrationService.FormFieldType.INFOTEXTGROUP:
            return <InfoGroupText fieldConfig={field} />;
        case FleetOwnerRegistrationService.FormFieldType.RADIOGROUP:
            return (
                <Radio
                    useFormValue={useFormValue}
                    fieldConfig={field}
                    error={getErrorMessage(field, validatorResults)}
                />
            );
        default:
            return null;
    }
};

const getFilteredAndSortedFields = (fields: FleetOwnerRegistrationService.FormField[]) =>
    fields.filter((field) => !field.hidden).sort((f1, f2) => f1.position - f2.position);

export {
    getApiToFormValues,
    getFormToApiValues,
    getErrorMessage,
    getChildrenErrorMessages,
    getFormField,
    getFilteredAndSortedFields,
};
