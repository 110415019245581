import {IntlShape} from "react-intl";

import {Dash} from "common/constants/dash";

interface FormatNumberOptions {
    minFractionDigits?: number;
    maxFractionDigits?: number;
    emptyCellDash?: Dash;
}

export function formatNumber(intl: IntlShape, value?: number | null, options?: FormatNumberOptions): string {
    if (!value) {
        return options?.emptyCellDash ?? Dash.EM;
    }
    const maxFractionDigits = options?.maxFractionDigits ?? 2;
    const minFractionDigits = options?.minFractionDigits ?? 1;
    return intl.formatNumber(value, {
        maximumFractionDigits: maxFractionDigits,
        minimumFractionDigits: minFractionDigits,
    });
}
