import {useCallback, useContext} from "react";

import LanguageSelector from "common/components/languageSelector";
import {useAuthentication} from "common/hooks/useAuthentication";
import {ApplicationContextProvider} from "common/providers/appStateProvider";

import {Button} from "@bolteu/kalep-react";

import LogoLink from "./LogoLink";

const Header = () => {
    const appState = useContext(ApplicationContextProvider);
    const {redirectToDriverPortalForLogin, makeLogout} = useAuthentication();

    const onAuthenticate = useCallback(() => {
        redirectToDriverPortalForLogin();
    }, [redirectToDriverPortalForLogin]);

    return (
        <nav className="bg-neutral-25 inset-x-0 top-0 z-[25] px-6 md:px-16">
            <div className="flex h-full flex-col justify-between">
                <div className="flex justify-between py-4">
                    <LogoLink to="/" />
                    <div className="flex gap-4">
                        <LanguageSelector />
                        {appState.isAuthenticated ? (
                            <Button onClick={makeLogout} size="sm" variant="secondary">
                                Logout
                            </Button>
                        ) : (
                            <Button onClick={onAuthenticate} size="sm">
                                Login
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;
