import {useCallback, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useAuthentication} from "common/hooks/useAuthentication";
import {FetchStatus, useFetch, useFetchNoAuth} from "common/hooks/useFetch";
import {ApplicationContextProvider} from "common/providers/appStateProvider";
import {VehicleMarketplaceNoAuthApiClient} from "common/services/api/api_clients/noAuthApiClient";
import {Api} from "common/services/api/apiProvider";
import {useReactRouterLinkForBreadcrumb} from "@fleet/common/hooks/useBreadcrumbRenderItem";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceNoAuthService, VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Breadcrumb, Spinner} from "@bolteu/kalep-react";

import {ListingDetails} from "./components/ListingDetails";
import {PriceWithRequestVehicle} from "./components/PriceWithRequestVehicle";
import {useBreadcrumbs} from "./hooks/useBreadcrumbs";
import {canRequestVehicle} from "./util";

const getListingFunction = (query: VehicleMarketplaceNoAuthService.GetListingRequest) =>
    VehicleMarketplaceNoAuthApiClient.webVehicleListingGet(query);
const getListingAuthFunction = (api: Api, query: VehicleMarketplaceService.GetListingRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleListingGet(query);
const createApplicationFunction = (api: Api, query: VehicleMarketplaceService.CreateApplicationRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleApplicationCreate(query);

const ListingPage = () => {
    const location = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const appState = useContext(ApplicationContextProvider);

    const breadcrumbs = useBreadcrumbs();
    const {redirectToDriverPortalForLogin} = useAuthentication();
    const {getCreateApplicationPath} = useAbsolutePath();

    const isMobile = useTailwindViewport() === "sm";
    const listingId = Number(params.listing_id);

    const [listingData, setListingData] = useState<VehicleMarketplaceService.GetListingPartnerResponse | null>(null);

    const {
        fetch: fetchNoAuthListing,
        data: fetchNoAuthListingData,
        status: fetchNoAuthListingStatus,
    } = useFetchNoAuth(getListingFunction);
    const {
        fetch: fetchAuthListing,
        data: fetchAuthListingData,
        status: fetchAuthListingStatus,
    } = useFetch(getListingAuthFunction);
    const {
        fetch: fetchCreateApplication,
        data: fetchCreateApplicationData,
        status: fetchCreateApplicationStatus,
    } = useFetch(createApplicationFunction);

    const requestVehicle = useCallback(() => {
        if (fetchAuthListingData?.application_details) {
            navigate(getCreateApplicationPath(listingId, fetchAuthListingData?.application_details.id));
        } else if (fetchCreateApplication) {
            fetchCreateApplication({listing_id: listingId});
        }
    }, [
        fetchCreateApplication,
        listingId,
        fetchAuthListingData?.application_details,
        getCreateApplicationPath,
        navigate,
    ]);

    useEffect(() => {
        if (fetchCreateApplicationStatus === FetchStatus.Success) {
            navigate(getCreateApplicationPath(listingId, fetchCreateApplicationData.application_id));
        }
    }, [navigate, getCreateApplicationPath, listingId, fetchCreateApplicationStatus, fetchCreateApplicationData]);

    const requestVehicleClicked = useCallback(() => {
        if (appState.isAuthenticated) {
            requestVehicle();
            return;
        }
        const path = location.pathname[0] === "/" ? location.pathname.slice(1) : location.pathname;
        const redirectTo = `${path}?request_vehicle=true`;
        redirectToDriverPortalForLogin(redirectTo);
    }, [appState.isAuthenticated, location.pathname, requestVehicle, redirectToDriverPortalForLogin]);

    useEffect(() => {
        const wasAuthedDueToVehicleRequested = Boolean(
            [...searchParams.keys()].find((key) => key === "request_vehicle"),
        );
        if (wasAuthedDueToVehicleRequested && listingData) {
            // Clear the redirection search params
            navigate({pathname: location.pathname, search: ""}, {replace: true});
            const applicationStatus = listingData.application_details?.status;
            if (canRequestVehicle(applicationStatus)) {
                requestVehicle();
            }
        }
    }, [listingData, location.pathname, navigate, requestVehicle, searchParams]);

    const onApplicationDeactivated = useCallback(() => {
        setListingData((prev) => {
            if (!prev) {
                return prev;
            }
            return {
                listing_details: prev.listing_details,
                listing_images: prev.listing_images,
                application_details: prev.application_details && {
                    ...prev.application_details,
                    status: VehicleMarketplaceService.ApplicationStatus.INACTIVE,
                },
                application_id: prev.application_id,
                company_details: prev.company_details,
            };
        });
    }, []);

    useEffect(() => {
        if (!listingId || Number.isNaN(listingId)) {
            return;
        }
        if (!appState.isAuthenticated && fetchNoAuthListing) {
            fetchNoAuthListing({listing_id: listingId});
        } else if (appState.isAuthenticated && fetchAuthListing) {
            fetchAuthListing({listing_id: listingId});
        }
    }, [appState.isAuthenticated, fetchAuthListing, fetchNoAuthListing, listingId]);

    useEffect(() => {
        if (fetchAuthListingStatus === FetchStatus.Success) {
            setListingData(fetchAuthListingData);
        }
    }, [fetchAuthListingData, fetchAuthListingStatus]);

    useEffect(() => {
        if (fetchNoAuthListingStatus === FetchStatus.Success) {
            setListingData({
                ...fetchNoAuthListingData,
                company_details: {
                    ...fetchNoAuthListingData.company_details,
                    contacts: null,
                },
                application_details: null,
                application_id: null,
            });
        }
    }, [fetchNoAuthListingData, fetchNoAuthListingStatus]);

    const isLoading =
        fetchNoAuthListingStatus === FetchStatus.Loading || fetchAuthListingStatus === FetchStatus.Loading;

    let Body = null;
    if (
        Number.isNaN(listingId) ||
        fetchNoAuthListingStatus === FetchStatus.Error ||
        fetchAuthListingStatus === FetchStatus.Error
    ) {
        Body = <ErrorView type={ErrorViewType.NotFound} className="!bg-neutral-25" />;
    } else if (isLoading || !listingData) {
        Body = (
            <div className="mt-20 w-full text-center">
                <Spinner />
            </div>
        );
    } else {
        Body = (
            <ListingDetails
                data={listingData}
                requestVehicleClicked={requestVehicleClicked}
                applicationDeactivated={onApplicationDeactivated}
            />
        );
    }

    const isRequestVehicleButtonShown = !!listingData && canRequestVehicle(listingData?.application_details?.status);

    return (
        <>
            <div className="container mx-auto flex flex-col gap-8 px-6 pt-4 pb-12 md:px-16">
                <Breadcrumb items={breadcrumbs} renderItem={useReactRouterLinkForBreadcrumb} />
                {Body}
            </div>
            {isMobile && isRequestVehicleButtonShown && (
                <PriceWithRequestVehicle
                    listingData={listingData.listing_details.data}
                    requestVehicleClicked={requestVehicleClicked}
                    isSticky
                    isRequestVehicleButtonShown={isRequestVehicleButtonShown}
                />
            )}
        </>
    );
};

export default ListingPage;
