import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";

export const useBreadcrumbs = () => {
    const {i18n} = useI18n();
    const {getHomePath} = useAbsolutePath();

    return [
        {
            href: getHomePath(),
            title: i18n("listing.breadcrumbs.vehicles"),
        },
        {
            href: "",
            title: i18n("listing.breadcrumbs.vehicle_details"),
        },
    ];
};
