import {Island, Typography} from "@bolteu/kalep-react";

interface Props {
    title?: string;
    children: React.ReactNode;
    hasAction?: boolean;
}

export const DetailsCard = ({children, title, hasAction}: Props) => {
    const bottomPadding = hasAction ? "" : "pb-2";

    return (
        <Island className="border-0">
            <div className={`flex flex-col gap-4 px-3 pt-2 ${bottomPadding}`}>
                {!!title && (
                    <Typography fontSize="text-lg" fontWeight="semibold">
                        {`${title}:`}
                    </Typography>
                )}
                {children}
            </div>
        </Island>
    );
};
