import {getVehicleColorClasses} from "@fleet/common/utils/vehicle/vehicleColorUtil";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";

interface Props {
    listingData: VehicleMarketplaceService.ListingDataForApplication;
    listingDataTranslations: VehicleMarketplaceService.ListingFieldsTranslationsForApplication;
}

const Details = ({listingData, listingDataTranslations}: Props) => {
    const vehicleColors = listingData.vehicle_colors;
    const vehicleColorTranslations = listingDataTranslations.vehicle_colors;

    return (
        <div>
            <h3 className="bolt-font-heading-s-accent pb-3 font-semibold">{`${listingData.vehicle_model_name}, ${listingData.vehicle_years}`}</h3>
            {vehicleColorTranslations && (
                <div className="flex flex-wrap gap-3">
                    {vehicleColors.map((color) => (
                        <div className="flex items-center gap-2">
                            <div className={`h-4 w-4 rounded ${getVehicleColorClasses(color)}`} />
                            <span>{vehicleColorTranslations[color]}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Details;
