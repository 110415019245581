import {useCallback} from "react";

import ErrorIcon from "assets/icons/error.svg?react";
import NotFoundIcon from "assets/icons/not-found.svg?react";
import UnavailableIcon from "assets/icons/unavailable.svg?react";
import {useI18n} from "common/hooks/useI18n";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {Button} from "@bolteu/kalep-react";

import {ErrorViewType} from "./ErrorView";

interface ServiceUnavailableErrorProps {
    type: ErrorViewType.ServiceUnavailable;
    customTitle?: string;
    onActionClick?: never;
    className?: string;
}

interface NotFoundErrorProps {
    type: ErrorViewType.NotFound;
    customTitle?: never;
    onActionClick?: never;
    className?: string;
}

interface SomethingWentWrongErrorProps {
    type: ErrorViewType.SomethingWentWrong;
    customTitle?: never;
    onActionClick?: () => void;
    className?: string;
}

export type ErrorViewProps = ServiceUnavailableErrorProps | NotFoundErrorProps | SomethingWentWrongErrorProps;

interface ErrorViewValues {
    title: string;
    description: string | React.ReactNode;
    icon: React.ReactNode;
    ActionComponent: React.ReactNode;
}

export function useErrorView({type, customTitle, onActionClick}: ErrorViewProps): ErrorViewValues {
    const {i18n} = useI18n();

    const refreshPage = useCallback(async () => {
        window.location.reload();
    }, []);

    if (type === ErrorViewType.ServiceUnavailable) {
        return {
            title: customTitle || i18n("error_view.service_unavailable.title"),
            description: i18n("error_view.service_unavailable.description"),
            icon: <UnavailableIcon />,
            ActionComponent: null,
        };
    }

    if (type === ErrorViewType.NotFound) {
        return {
            title: i18n("error_view.not_found.title"),
            description: i18n("error_view.not_found.description"),
            icon: <NotFoundIcon />,
            ActionComponent: null,
        };
    }

    if (type === ErrorViewType.SomethingWentWrong) {
        return {
            title: i18n("error_view.something_went_wrong.title"),
            description: i18n("error_view.something_went_wrong.description"),
            icon: <ErrorIcon />,
            ActionComponent: (
                <Button variant="secondary" onClick={onActionClick ?? refreshPage}>
                    {i18n("error_view.something_went_wrong.action_text")}
                </Button>
            ),
        };
    }

    return UnreachableCode.never(type);
}
