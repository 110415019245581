import {Page} from "common/components/page/Page";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";

import Details from "./components/Details";
import Pricing from "./components/Pricing";
import VehiclePicture from "./components/VehiclePicture";
import WizardContent from "./components/WizardContent";

import BodyType = VehicleMarketplaceService.CarModelBodyType;

interface Props {
    applicationId: number;
    listingData: VehicleMarketplaceService.ListingDataForApplication;
    listingTranslations: VehicleMarketplaceService.ListingFieldsTranslationsForApplication;
    wizardFields: FleetOwnerRegistrationService.FormField[];
    onClose: () => void;
    onSubmit: () => void;
}

export const ApplicationWizardDesktopView = ({
    listingData,
    listingTranslations,
    wizardFields,
    applicationId,
    onClose,
    onSubmit,
}: Props) => {
    const {i18n} = useI18n();

    return (
        <Page>
            <div className="grid h-full grid-cols-12 gap-4">
                <div className="col-span-4">
                    <div className="bg-layer-surface flex h-full flex-col gap-6 overflow-y-auto rounded-lg px-6 pt-4 pb-10">
                        <VehiclePicture vehicleModelBodyType={listingData.model_body_type ?? BodyType.SEDAN} />
                        <Details listingData={listingData} listingDataTranslations={listingTranslations} />
                        <Pricing listingData={listingData} listingDataTranslations={listingTranslations} />
                    </div>
                </div>
                <div className="col-span-full col-start-6 flex flex-col">
                    <div className="flex flex-col gap-2">
                        <h2 className="bolt-font-heading-m-accent">
                            {i18n("application_wizard.rental_requirements.header")}
                        </h2>
                        <p>{i18n("application_wizard.rental_requirements.description")}</p>
                    </div>
                    <WizardContent
                        applicationId={applicationId}
                        fields={wizardFields}
                        onClose={onClose}
                        onSubmit={onSubmit}
                    />
                </div>
            </div>
        </Page>
    );
};
