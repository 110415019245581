import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Alert, Typography} from "@bolteu/kalep-react";

import {useApplicationAlert} from "../hooks/useApplicationAlert";

interface Props {
    application: VehicleMarketplaceService.ApplicationDetails | null;
    vehicleName: string;
    onApplicationDeactivated: () => void;
}

export const ApplicationAlert = ({application, vehicleName, onApplicationDeactivated}: Props) => {
    const data = useApplicationAlert({
        application,
        vehicleName,
        onApplicationDeactivated,
    });

    if (!data) {
        return null;
    }

    const {severity, title, description, action} = data;
    return (
        <Alert
            severity={severity}
            title={title}
            action={action}
            overrideClassName="[&>div>div[role='button']]:!text-sm [&>div>div[role='button']]:!mt-2"
        >
            <Typography color="primary" fontSize="text-sm">
                {description}
            </Typography>
        </Alert>
    );
};
