import {useIntl} from "react-intl";

import {Page} from "common/components/page/Page";
import {useI18n} from "common/hooks/useI18n";
import {formatCurrencyValue} from "common/util/formatCurrencyValue";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";

import ViewVehicleDetails from "./components/ViewVehicleDetails";
import WizardContent from "./components/WizardContent";

interface Props {
    applicationId: number;
    listingId: number;
    listingData: VehicleMarketplaceService.ListingDataForApplication;
    listingTranslations: VehicleMarketplaceService.ListingFieldsTranslationsForApplication;
    wizardFields: FleetOwnerRegistrationService.FormField[];
    onClose: () => void;
    onSubmit: () => void;
}

export const ApplicationWizardMobileView = ({
    listingId,
    listingData,
    listingTranslations,
    wizardFields,
    applicationId,
    onClose,
    onSubmit,
}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();

    return (
        <Page>
            <div className="flex flex-col gap-6">
                <h3 className="bolt-font-heading-s-accent pb-3 font-semibold">{i18n("application_wizard.header")}</h3>
                <div className="bg-layer-surface flex flex-col gap-1 overflow-y-auto rounded-lg p-4">
                    <p className="bolt-font-body-l-accent">
                        {listingData.vehicle_model_name}, {listingData.vehicle_years}
                    </p>
                    <div>
                        <span>{formatCurrencyValue(intl, listingData.offer_price, listingData.currency_symbol)}</span>
                        <span> / </span>
                        <span>{listingTranslations.pricing_fields?.values.offer_period?.value}</span>
                    </div>
                    <ViewVehicleDetails listingId={Number(listingId)} />
                </div>
                <div className="flex flex-col gap-2">
                    <h4 className="bolt-font-heading-xs-accent">
                        {i18n("application_wizard.rental_requirements.header")}
                    </h4>
                    <p>{i18n("application_wizard.rental_requirements.description")}</p>
                </div>
                <WizardContent
                    applicationId={applicationId}
                    fields={wizardFields}
                    onClose={onClose}
                    onSubmit={onSubmit}
                />
            </div>
        </Page>
    );
};
