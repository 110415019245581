import {useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";

import {AdditionalFields, FormValueType} from "common/components/dynamic_form/types";
import {
    getApiToFormValues,
    getFilteredAndSortedFields,
    getFormField,
    getFormToApiValues,
} from "common/components/dynamic_form/util";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/api/apiProvider";
import {getDateFormat} from "common/util/getFormatUtil";
import {getIsoDate} from "common/util/getIsoDate";
import {ValidatorResult} from "@fleet/common/hooks/useFetch";

import {VehicleMarketplaceService as VehicleMarketplaceServiceFOP} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Button, DatePicker, Link, Typography} from "@bolteu/kalep-react";

import FormField = VehicleMarketplaceServiceFOP.FormField;

export const applicationUpdateFunction = (api: Api, body: VehicleMarketplaceService.UpdateApplicationRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleApplicationUpdate(body);
export const applicationPublishFunction = (api: Api, body: VehicleMarketplaceService.PublishApplicationRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleApplicationPublish(body);

interface Props {
    fields: FormField[];
    applicationId: number;
    onClose: () => void;
    onSubmit: () => void;
    feedbackLink?: string;
}

const WizardContentHeader = ({title, subtitle}: {title: string; subtitle?: string}) => {
    return (
        <div className="flex flex-col gap-6">
            <Typography variant="title-primary" fontSize="text-3xl">
                {title}
            </Typography>
            {subtitle && <Typography variant="body-primary">{subtitle}</Typography>}
        </div>
    );
};

const WizardContent = ({fields, applicationId, onClose, onSubmit, feedbackLink}: Props) => {
    const {i18n} = useI18n();
    const intl = useIntl();

    const {fetch: update, status: updateStatus, error: updateError} = useFetch(applicationUpdateFunction);
    const {fetch: publish, status: publishStatus, error: publishError} = useFetch(applicationPublishFunction);

    const [validatorResults, setValidatorResults] = useState<ValidatorResult[]>([]);
    const [startRentingDate, setStartRentingDate] = useState<Date>(new Date());

    const formValue = getApiToFormValues(fields);
    const useFormValue = useForm<FormValueType, AdditionalFields>(formValue);

    const {Form, Button: FormButton} = useFormValue;

    useEffect(() => {
        if (updateError.validationErrors) {
            setValidatorResults(updateError.validationErrors);
        }
    }, [updateError.validationErrors]);

    useEffect(() => {
        if (publishError.validationErrors) {
            setValidatorResults(publishError.validationErrors);
        }
    }, [publishError.validationErrors]);

    useEffect(() => {
        if (updateStatus === FetchStatus.Success && publish) {
            publish({application_id: applicationId});
        }
    }, [updateStatus, publish, applicationId]);

    useEffect(() => {
        if (publishStatus === FetchStatus.Success) {
            onSubmit();
        }
    }, [publishStatus, onSubmit]);

    const onSubmitForm = useCallback(
        async (state: FormState<FormValueType>) => {
            if (!update) {
                return;
            }
            const {data} = state;
            await update({
                applicant_requirement_answers:
                    getFormToApiValues<VehicleMarketplaceService.ApplicantRequirementAnswers>(data, fields),
                application_id: applicationId,
                planned_start_date: getIsoDate(startRentingDate),
            });
        },
        [fields, applicationId, startRentingDate, update],
    );

    const onStartRentingDateChange = useCallback(
        (date: Date | Date[] | null) => {
            if (date && !Array.isArray(date)) {
                setStartRentingDate(date);
            }
        },
        [setStartRentingDate],
    );

    const FeedbackLink = () =>
        feedbackLink ? (
            <p>
                {i18n("application_wizard.rental_requirements.submit_feedback_note.text", {
                    feedback_link_text: (
                        <Link href={feedbackLink} target="_blank" overrideClassName="font-semibold">
                            {i18n("application_wizard.listing_data.view_vehicle_details")}
                        </Link>
                    ),
                })}
            </p>
        ) : null;

    const isLoading = updateStatus === FetchStatus.Loading;

    return (
        <Form validatorResults={validatorResults} onSubmit={onSubmitForm}>
            <div className="flex flex-col gap-8">
                <div className="flex max-w-full flex-col gap-6">
                    {getFilteredAndSortedFields(fields).map((field) => (
                        <div key={field.name} className="w-full">
                            <div className="w-3/4">{getFormField(field, validatorResults, useFormValue)}</div>
                        </div>
                    ))}
                </div>
                <DatePicker
                    label={i18n("application_wizard.rental_requirements.start_renting_date_picker_title")}
                    value={startRentingDate}
                    onChange={onStartRentingDateChange}
                    format={getDateFormat(intl)}
                    minDate={new Date()}
                    locale={intl.locale}
                    fullWidth
                />
                <div className="text-secondary bolt-font-body-s-regular">
                    <p>{i18n("application_wizard.rental_requirements.submit_request_note")}</p>
                    <FeedbackLink />
                </div>
                <div className="flex justify-between">
                    <Button variant="secondary" onClick={onClose} disabled={isLoading}>
                        {i18n("common.back")}
                    </Button>
                    <FormButton submit loading={isLoading}>
                        {i18n("application_wizard.rental_requirements.submit_request_button_text")}
                    </FormButton>
                </div>
            </div>
        </Form>
    );
};

export default WizardContent;
export {WizardContentHeader};
